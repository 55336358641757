var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"text-h5 mb-sm-4 pa-4 pa-sm-0 primary--text d-flex align-center"},[_c('v-breadcrumbs',{staticClass:"pl-4 pb-0 pt-0",attrs:{"items":_vm.breadcrumb,"large":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-breadcrumbs-item',{staticClass:"text-h6 pl-0 pt-0",attrs:{"link":"","to":item.href,"exact":"","disabled":item.disabled}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t(item.text))+" ")])])]}}])}),(_vm.model.order && _vm.model.order.service_type_id)?_c('div',{staticClass:"primary--text ml-2 px-0 text-h6"},[(_vm.model.order && _vm.model.order.service_type_id)?_c('span',[_vm._v(" "+_vm._s(_vm.editComponents[_vm.model.order.service_type_id].toUpperCase())+" ")]):_vm._e(),_c('span',{staticClass:"ml-1"},[_vm._v(" ( "+_vm._s(_vm.model && _vm.model.order && _vm.model.order.number)+" ) ")])]):_vm._e()],1),_c('v-card',{staticClass:"elevation-4"},[_c('v-card-text',[_c('v-row',{staticClass:"mb-2"},[_c('v-col',{attrs:{"cols":"9","align-self":"center"}}),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"3","align-self":"center"}},[_c('v-btn',{staticClass:"mr-2 mt-1",attrs:{"icon":"","title":_vm.$t('global_buttons.reload')},on:{"click":function($event){return _vm.loadData()}}},[_c('v-icon',[_vm._v("mdi-reload")])],1)],1)],1),_c('v-card',{staticClass:"table-container",attrs:{"outlined":""}},[_c('v-menu',{attrs:{"position-x":_vm.x,"position-y":_vm.y,"absolute":"","offset-y":"","transition":"scale-transition"},model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}},[_c('v-list',{staticClass:"pa-0 pt-1"},[_c('v-list-item',{staticClass:"d-flex flex-column pa-0 align-start",staticStyle:{"min-height":"5px"}},[_c('div',{staticClass:"d-flex justify-start"},[_c('v-btn',{attrs:{"title":_vm.$t('global_buttons.edit'),"link":"","to":("/clients/edit/" + _vm.activeItemId),"text":"","small":""}},[_c('v-icon',{attrs:{"size":"16"}},[_vm._v(" mdi-pencil ")]),_c('span',{staticClass:"text--accent-1"},[_vm._v(" "+_vm._s(_vm.$t("global_buttons.edit"))+" ")])],1)],1),_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',{directives:[{name:"confirm",rawName:"v-confirm",value:({
                    title: _vm.$t('global_alert.deletion_title'),
                    message: _vm.$t('global_alert.deletion_text'),
                    callback: function () {
                      _vm.deleteRow(_vm.activeItemId);
                    }
                  }),expression:"{\n                    title: $t('global_alert.deletion_title'),\n                    message: $t('global_alert.deletion_text'),\n                    callback: () => {\n                      deleteRow(activeItemId);\n                    }\n                  }"}],attrs:{"title":_vm.$t('global_buttons.delete'),"color":"error","text":"","small":""}},[_c('v-icon',{attrs:{"size":"16"}},[_vm._v(" mdi-delete ")]),_c('span',{staticClass:"text--accent-1"},[_vm._v(" "+_vm._s(_vm.$t("global_buttons.delete"))+" ")])],1)],1)])],1)],1),_c('v-data-table',{attrs:{"dense":"","items":_vm.items,"headers":_vm.headers,"loading":_vm.loading,"items-per-page":25,"footer-props":{
            itemsPerPageOptions: [10, 25, 50, 100, 200]
          }},scopedSlots:_vm._u([_vm._l((_vm.headers),function(header,headerIndex){return {key:("item." + (header.value)),fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{key:headerIndex,attrs:{"max-width":"500","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"200px"}},'span',attrs,false),on),[_vm._v(_vm._s(item[header.value])+" ")]),(item.id === _vm.activeItemId)?void 0:_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(item[header.value])+" ")])])]}}}),{key:"item.type",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.selects.statuses[item.type])+" ")]}},{key:"item.details",fn:function(ref){
          var item = ref.item;
return [_c('slot-wrapper',{attrs:{"item":item,"type-key":"type"},scopedSlots:_vm._u([{key:"order_updated",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [(Object.keys(item.details.new_values).length)?_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('span',[_vm._v(" "+_vm._s(_vm.$t( ("orders.form.fields." + (Object.keys(item.details.new_values)[0])) ))+" ")]),(Object.keys(item.details.new_values).length > 1)?_c('span',[_vm._v("(+"+_vm._s(Object.keys(item.details.new_values).length)+")")]):_vm._e()]):_vm._e()]}}],null,true)},[_c('div',_vm._l((Object.keys(
                        item.details.new_values
                      ).map(function (el) { return _vm.$t(("orders.form.fields." + el)); })),function(text){return _c('div',{key:text},[_vm._v(" "+_vm._s(text)+" ")])}),0)])]}},{key:"service_created",fn:function(ref){
                      var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.details.name)+" ")])]}},{key:"service_deleted",fn:function(ref){
                      var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.details.name)+" ")])]}},{key:"status_updated",fn:function(ref){
                      var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-start"},[_c('div',[_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.details.old_values)+" ")])]),_c('v-icon',{staticClass:"mx-2",attrs:{"size":"16"}},[_vm._v("mdi-arrow-right")]),_c('div',[_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.details.new_values)+" ")])]),(item.details.comment)?_c('div',{staticClass:"text-no-wrap"},[_c('span',[_vm._v(",")]),_c('span',{staticClass:"ml-2 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("orders.form.fields.comment"))+": ")]),_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.details.comment)+" ")])]):_vm._e()],1)]}},{key:"payment_created",fn:function(ref){
                      var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-start"},[_c('div',{staticClass:"font-weight-bold"},[_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.details.sum)+" MDL ")])]),_c('div',{staticClass:"ml-2"},[_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.details.method)+" ")])])])]}},{key:"payment_deleted",fn:function(ref){
                      var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-start"},[_c('div',{staticClass:"font-weight-bold"},[_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.details.sum)+" MDL ")])]),_c('div',{staticClass:"ml-2"},[_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.details.method)+" ")])])])]}},{key:"guarantee_created",fn:function(ref){
                      var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-start"},[_c('div',{staticClass:"font-weight-bold"},[_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.details.sum)+" MDL ")])]),_c('div',{staticClass:"ml-2"},[_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.details.type)+" ")])])])]}},{key:"guarantee_deleted",fn:function(ref){
                      var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-start"},[_c('div',{staticClass:"font-weight-bold"},[_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.details.sum)+" MDL ")])]),_c('div',{staticClass:"ml-2"},[_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.details.type)+" ")])])])]}}],null,true)})]}},{key:"item.actions",fn:function(ref){
                      var item = ref.item;
return [(
                [
                  'order_created',
                  'order_updated',
                  'payment_created',
                  'guarantee_created'
                ].includes(item.type)
              )?_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',{attrs:{"title":_vm.$t('global_buttons.edit'),"x-small":"","icon":""},on:{"click":function($event){return _vm.selectRow(item)}}},[_c('v-icon',{attrs:{"size":"16"}},[_vm._v(" mdi-view-list ")])],1)],1):_vm._e()]}}],null,true)})],1)],1)],1),_c('v-dialog',{attrs:{"width":"800"},model:{value:(_vm.detailsDialog),callback:function ($$v) {_vm.detailsDialog=$$v},expression:"detailsDialog"}},[(_vm.detailsDialog)?_c('v-card',[_c('v-card-title',[_c('span',[_vm._v(" "+_vm._s(_vm.selects.statuses[_vm.selectedRow.type])+" ")]),_c('v-spacer'),_c('v-icon',{on:{"click":function($event){return _vm.closeSelected()}}},[_vm._v("mdi-close")])],1),_c('v-divider'),_c('v-card-text',{staticStyle:{"height":"60vh","overflow":"auto"}},[_c('slot-wrapper',{attrs:{"item":_vm.selectedRow,"type-key":"type"},scopedSlots:_vm._u([{key:"order_created",fn:function(ref){
              var item = ref.item;
return [_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("orders.history.field_name")))]),_c('th',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("orders.history.value"))+" ")])])]),_c('tbody',_vm._l((item.details),function(value,key){return _c('tr',{key:key},[_c('td',[_vm._v(" "+_vm._s(_vm.$t(("orders.form.fields." + key)))+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(value))])])}),0)]},proxy:true}],null,true)})]}},{key:"order_updated",fn:function(ref){
              var item = ref.item;
return [_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("orders.history.field_name")))]),_c('th',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("orders.history.old_value"))+" ")]),_c('th'),_c('th',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("orders.history.new_value"))+" ")])])]),_c('tbody',_vm._l((item.details.new_values),function(value,key){return _c('tr',{key:key},[_c('td',[_vm._v(" "+_vm._s(_vm.$t(("orders.form.fields." + key)))+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.details.old_values[key])+" ")]),_c('td',{staticClass:"text-center"},[_c('v-icon',{attrs:{"size":"16"}},[_vm._v("mdi-arrow-right")])],1),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(value))])])}),0)]},proxy:true}],null,true)})]}},{key:"payment_created",fn:function(ref){
              var item = ref.item;
return [_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-avatar',[_c('v-icon',[_vm._v("mdi-cash-plus")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("orders.history.sum"))+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.details.sum)+" ")])],1)],1),_c('v-divider'),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-avatar',[_c('v-icon',[_vm._v("mdi-account-cash")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("orders.history.payment_method")))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.details.method)+" MDL")])],1)],1),_c('v-divider'),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-avatar',[_c('v-icon',[_vm._v("mdi-file-document")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("orders.history.document")))]),_c('v-list-item-subtitle',[(item.details.document)?_c('a',{attrs:{"href":item.details.document.url,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t("orders.history.view"))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t("orders.history.no_document"))+" ")])])],1)],1)]}},{key:"guarantee_created",fn:function(ref){
              var item = ref.item;
return [_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-avatar',[_c('v-icon',[_vm._v("mdi-check-all")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Статус")]),_c('v-list-item-subtitle',[_c('v-chip',{staticClass:"mt-1",attrs:{"small":"","color":"primary"}},[_vm._v(" "+_vm._s(item.details.status)+" ")])],1)],1)],1),_c('v-divider'),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-avatar',[_c('v-icon',[_vm._v("mdi-cash-plus")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("orders.history.sum")))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.details.sum)+" MDL")])],1)],1),_c('v-divider'),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-avatar',[_c('v-icon',[_vm._v("mdi-account-cash")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("orders.history.payment_method")))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.details.type))])],1)],1),_c('v-divider'),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-avatar',[_c('v-icon',[_vm._v("mdi-file-document")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("orders.history.document")))]),_c('v-list-item-subtitle',[(item.details.document)?_c('a',{attrs:{"href":item.details.document.url,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t("orders.history.view")))]):_c('span',[_vm._v(" "+_vm._s(_vm.$t("orders.history.no_document"))+" ")])])],1)],1)]}}],null,false,729097712)})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }