
import Vue from "vue";

export default Vue.extend({
  name: "SlotWrapper",

  props: {
    item: {
      required: true
    },
    typeKey: {
      required: true,
      type: String
    }
  }
});
