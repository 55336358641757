
import Vue from "vue";
import API from "@/api/API";
import store from "@/store";
import { Dictionary } from "vue-router/types/router";
import tablePagination from "@/mixins/table-pagination";
import tableFilters from "@/mixins/table-filters";
import { parseArrayParams } from "@/services/helpers";
import SlotWrapper from "@/components/form-components/SlotWrapper.vue";

export default Vue.extend({
  name: "History",

  components: { SlotWrapper },

  mixins: [tablePagination, tableFilters],

  data: () => ({
    parseArrayParams,
    filter: {} as Dictionary<string | (string | null)[]>,
    headers: [] as Array<any>,
    items: [] as Array<any>,
    loading: false as boolean,
    showMenu: false,
    x: 0,
    y: 0,
    selectedRow: null,
    detailsDialog: false,
    activeItemId: null,
    selects: {
      statuses: {}
    },
    breadcrumb: [
      {
        text: "orders.title",
        disabled: false,
        href: "/orders"
      },
      {
        text: "orders.form.header.history",
        disabled: true,
        href: `/orders/history`
      }
    ],
    model: {} as any,
    changeStatusDialog: false as boolean,
    editComponents: {
      1: "ata",
      2: "aaij",
      3: "cert",
      4: "eval",
      5: "exp"
    }
  }),

  async beforeRouteEnter(to, from, next) {
    try {
      const params = {
        filter: to.query,
        page: {
          number: to.params.page,
          size: 50
        }
      };
      const response = await API.orders().getLogs(to.params.id);
      const statuses = await API.orders().getLogsStatuses();
      const order = await API.orders().show(Number(to.params.id));

      next(vm => {
        vm.setFilters(params.filter);
        vm.selects.statuses = statuses;
        vm.setServerResponse(response);
        vm.model = order;
      });
    } catch (e) {
      await store.dispatch("alert/showError", e.message);
    }
  },

  methods: {
    async loadData(): Promise<void> {
      this.loading = true;
      try {
        const response = await this.$API
          .orders()
          .getLogs(this.$route.params.id);
        this.setServerResponse(response);
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
    },
    async deleteRow(id: number): Promise<void> {
      this.loading = true;
      try {
        await this.$API.clients().delete(id);
        await this.$store.dispatch(
          "alert/showSuccess",
          this.$t("global_alert.successful_removal")
        );
        await this.loadData();
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
    },
    setFilters(filter: Dictionary<string | (string | null)[]>): void {
      const newFilters = this.getAvailableFilters(filter);
      if (Object.keys(newFilters).length) {
        this.showFilters = true;
      }

      this.filter = newFilters;
      this.changeActiveFilters();
    },
    setServerResponse(items: any) {
      this.headers = [
        {
          text: this.$t("orders.history.type"),
          value: "type",
          width: "200px"
        },
        {
          text: this.$t("orders.history.author"),
          value: "author",
          width: "200px"
        },
        {
          text: this.$t("orders.history.datetime"),
          value: "created_at",
          width: "150px"
        },
        {
          text: this.$t("orders.history.details"),
          value: "details",
          align: "start"
        },
        {
          text: "",
          value: "actions",
          width: "50px",
          align: "center",
          sortable: false
        }
      ];
      this.items = items;
    },
    selectRow(row: any) {
      this.selectedRow = row;
      this.detailsDialog = true;
    },
    closeSelected() {
      this.detailsDialog = false;
      this.selectedRow = null;
    }
  }
});
